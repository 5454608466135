import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`7th October 2021`}</p>


    <h2 {...{
      "id": "-highlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-highlights",
        "aria-label": " highlights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌸 Highlights`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now enable `}<a parentName="p" {...{
            "href": "https://cloud.google.com/endpoints/docs/grpc/transcoding"
          }}>{`HTTP/JSON to GRPC transcoding`}</a>{`
in your `}<a parentName="p" {...{
            "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
          }}>{`type://GrpcService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3786"
          }}>{`#3786`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .addService(helloService)
           .enableHttpJsonTranscoding(true) // 👈👈👈
           .build();
`}</code></pre>
        <Tip mdxType="Tip">
          <p parentName="li">{`  HTTP/JSON transcoding is currently experimental. We're looking forward to your feedback.`}</p>
        </Tip>
      </li>
    </ul>
    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now bind a virtual host to a specific port. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3798"
          }}>{`#3798`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3833"
          }}>{`#3833`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .http(8080)
      .http(18080)
      // Bind the virtual host to 18080 port.
      .virtualHost(18080).service("/health", healthCheckService) // 👈👈👈
                         .serviceUnder("/secret/", secretService)
      .and()
      ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize any HTTP error status responses including a decoder-level error and
`}<a parentName="p" {...{
            "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
          }}>{`type://HttpStatusException`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3822"
          }}>{`#3822`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3853"
          }}>{`#3853`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.errorHandler(new ServerErrorHandler() {
    @Override
    public HttpResponse onServiceException(ServiceRequestContext ctx, Throwable cause) {
        // Convert an error that is raised in a service or a decorator.
        if (cause instanceof IllegalArgumentException) {
            return HttpResponse.of(HttpStatus.BAD_REQUEST);
        }
        ...
    }

    @Override
    public AggregatedHttpResponse onProtocolViolation(ServiceConfig config,
                                                      HttpStatus status,
                                                      @Nullable String description,
                                                      @Nullable Throwable cause) {
        // Convert the decoder-level error.
        if (status == HttpStatus.REQUEST_ENTITY_TOO_LARGE) {
            return AggregatedHttpResponse.of(HttpStatus.BAD_REQUEST);
        }
        ...
    }

    @Override
    public AggregatedHttpResponse renderStatus(ServiceConfig config,
                                               HttpStatus status,
                                               @Nullable String description,
                                               @Nullable Throwable cause) {
        // Convert the given error status and cause.
        ...
    }
})
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now share `}<a parentName="p" {...{
            "href": "type://ConcurrencyLimit:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimit.html"
          }}>{`type://ConcurrencyLimit`}</a>{` between `}<a parentName="p" {...{
            "href": "type://ConcurrencyLimitingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitingClient.html"
          }}>{`type://ConcurrencyLimitingClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3478"
          }}>{`#3478`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3753"
          }}>{`#3753`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3860"
          }}>{`#3860`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ConcurrencyLimit limit = ConcurrencyLimit.of(64); // Can send 64 requests concurrently.

WebClient client1 = WebClient
  .builder(...)
  .decorator(ConcurrencyLimitingClient.newDecorator(limit))
  .build(...);

WebClient client2 = WebClient
  .builder(...)
  .decorator(ConcurrencyLimitingClient.newDecorator(limit))
  // different options
  ...
  .build(...);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now allow all headers in `}<inlineCode parentName="p">{`Access-Control-Request-Headers`}</inlineCode>{` from a
`}<a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Glossary/Preflight_request"
          }}>{`preflight request`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://CorsServiceBuilder#allowAllRequestHeaders(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsServiceBuilder.html#allowAllRequestHeaders(boolean)"
          }}>{`type://CorsServiceBuilder#allowAllRequestHeaders(boolean)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3839"
          }}>{`#3839`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3843"
          }}>{`#3843`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.service("/cors", myService.decorate(CorsService.builder("http://example.com")
                                                  .allowRequestMethods(HttpMethod.GET)
                                                  .allowAllRequestHeaders(true) // 👈👈👈
                                                  .newDecorator()));
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`Specifying `}<inlineCode parentName="li">{`*`}</inlineCode>{` in `}<inlineCode parentName="li">{`Access-Control-Allow-Headers`}</inlineCode>{` does not work with
`}<a parentName="li" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Headers#browser_compatibility"
            }}>{`certain browsers`}</a>{`
so you should use `}<a parentName="li" {...{
              "href": "type://CorsServiceBuilder#allowAllRequestHeaders(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsServiceBuilder.html#allowAllRequestHeaders(boolean)"
            }}>{`type://CorsServiceBuilder#allowAllRequestHeaders(boolean)`}</a>{` to allow all headers.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now sanitize the preview of a request or a response. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3791"
          }}>{`#3791`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3795"
          }}>{`#3795`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ContentPreviewingService.builder(ContentPreviewerFactory.text(100))
                        .requestPreviewSanitizer(requestPreviewSanitizer) // 👈👈👈
                        .responsePreviewSanitizer(responsePreviewSanitizer) // 👈👈👈
                        .newDecorator();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A compressed file is automatically decompressed if `}<a parentName="p" {...{
            "href": "type://FileServiceBuilder#autoDecompress(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileServiceBuilder.html#autoDecompress(boolean)"
          }}>{`type://FileServiceBuilder#autoDecompress(boolean)`}</a>{`
is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3804"
          }}>{`#3804`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3810"
          }}>{`#3810`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`FileService.builder(FileServiceBuilderTest.class.getClassLoader(), "/")
           .serveCompressedFiles(true) // This should be set to true to serve compressed files.
           .autoDecompress(true) // 👈👈👈
           .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send a delayed `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` using `}<a parentName="p" {...{
            "href": "type://HttpResponse#delayed(Supplier,Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#delayed(java.util.function.Supplier,java.time.Duration)"
          }}>{`type://HttpResponse#delayed(Supplier,Duration)`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3698"
          }}>{`#3698`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3770"
          }}>{`#3770`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Supplier<HttpResponse> responseSupplier = ...
HttpResponse.delayed(responseSupplier, Duration.ofSeconds(5)); // 👈👈👈
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify different blocking task executors for different `}<a parentName="p" {...{
            "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
          }}>{`type://HttpService`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://ServiceBindingBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceBindingBuilder.html"
          }}>{`type://ServiceBindingBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3740"
          }}>{`#3740`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3754"
          }}>{`#3754`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .route()
      .get("/foo")
      .blockingTaskExecutor(fooExecutor) // 👈👈👈
      .build(fooService)
      .route()
      .get("/bar")
      .blockingTaskExecutor(barExecutor) // 👈👈👈
      .build(barService)
      .build()
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify an `}<a parentName="p" {...{
            "href": "type://AuthToken:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/AuthToken.html"
          }}>{`type://AuthToken`}</a>{` when building a `}<a parentName="p" {...{
            "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
          }}>{`type://HealthCheckedEndpointGroup`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3809"
          }}>{`#3809`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HealthCheckedEndpointGroup.builder(endpointGroup, "/health")
                          .auth(AuthToken.ofOAuth2("..."))
                          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now export different properties for different `}<a parentName="p" {...{
            "href": "typeplural://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
          }}>{`typeplural://RequestContextExportingAppender`}</a>{` to log
with `}<a parentName="p" {...{
            "href": "http://logback.qos.ch"
          }}>{`Logback`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3775"
          }}>{`#3775`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3769"
          }}>{`#3769`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`// logback.xml
<appender name="RCEA1" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
  ...
  <export>remote.ip</export>
</appender>

<appender name="RCEA2" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
  ...
  <export>remote.port</export>
</appender>
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now log HTTP/2 frames using the frame loggers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3817"
          }}>{`#3817`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<!-- logback.xml -->
<logger name="com.linecorp.armeria.logging.traffic.server.http2" level="TRACE" />
<logger name="com.linecorp.armeria.logging.traffic.client.http2" level="TRACE" />
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "grpc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#grpc",
        "aria-label": "grpc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`gRPC`}</h3>
    <ul>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "type://UnframedGrpcErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/UnframedGrpcErrorHandler.html"
        }}>{`type://UnframedGrpcErrorHandler`}</a>{` to map a non-OK gRPC status to an `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{`
in an unframed gRPC service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3777"
        }}>{`#3777`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3782"
        }}>{`#3782`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .addService(grpcService)
           .enableUnframedRequests(true) // This should be set to true to enable UnframedGrpcService.
           .unframedGrpcErrorHandler(UnframedGrpcErrorHandler.of()) // 👈👈👈
           .build();
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "kotlin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kotlin",
        "aria-label": "kotlin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kotlin`}</h3>
    <ul>
      <li parentName="ul">{`You can now use Kotlin `}<a parentName="li" {...{
          "href": "https://kotlinlang.org/docs/flow.html"
        }}>{`Flow`}</a>{` as a return type in an annotated service.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3756"
        }}>{`#3756`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3768"
        }}>{`#3768`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Get("/members")
@ProducesJson
fun listMembers() = flow { // 👈👈👈
    emit(Member(name = "foo", age = 10))
    emit(Member(name = "bar", age = 20))
    emit(Member(name = "baz", age = 30))
}
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "spring-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#spring-integration",
        "aria-label": "spring integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Spring integration`}</h3>
    <ul>
      <li parentName="ul">{`You can now configure an internal port with a Spring property file. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3798"
        }}>{`#3798`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3833"
        }}>{`#3833`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`armeria:
  ports:
    - port: 8080
  internal-services:
    # Prometheus exposition and health check service will be
    # exposed to 18080 port. The requests to 8080 port will return 404 Not Found.
    include: metrics, health
    port: 18080 # 👈👈👈
    protocols: http
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now specify all parameters of `}<a parentName="li" {...{
          "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
        }}>{`type://ServerBuilder`}</a>{` with a Spring property file. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3705"
        }}>{`#3705`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3735"
        }}>{`#3735`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`armeria:
  ports:
    - port: 0
  max-num-connections: 8
  idle-timeout: 2s
  ping-interval: 1s
  max-connection-age: 4s
  max-num-requests-per-connection: 4
  http2-initial-connection-window-size: 2mb
  http2-initial-stream-window-size: 4mb
  http2-max-streams-per-connection: 1
  ...
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "graphql",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#graphql",
        "aria-label": "graphql permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GraphQL`}</h3>
    <ul>
      <li parentName="ul">{`You can now specify a `}<inlineCode parentName="li">{`GraphQLSchema`}</inlineCode>{` or `}<inlineCode parentName="li">{`schemaUrls`}</inlineCode>{` when building a `}<a parentName="li" {...{
          "href": "type://GraphQlService"
        }}>{`type://GraphQlService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3708"
        }}>{`#3708`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3815"
        }}>{`#3815`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GraphQLSchema schema = ...
sb.service("/graphql", GraphqlService.builder()
                                     .schema(schema) // 👈👈👈
                                     ...
                                     .build());
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`You can now learn `}<a parentName="li" {...{
          "href": "https://armeria.dev/docs/advanced-streaming-backpressure"
        }}>{`how to write a streaming response`}</a>{`
using backpressure. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3397"
        }}>{`#3397`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3847"
        }}>{`#3847`}</a></li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` is now automatically propagated to other dispatchers
over `}<a parentName="li" {...{
          "href": "https://kotlinlang.org/docs/coroutines-overview.html"
        }}>{`Kotlin Coroutines`}</a>{` when
using annotated and gRPC services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3745"
        }}>{`#3745`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3849"
        }}>{`#3849`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` can now run the Servlets that
depends on the TLS attributes. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3850"
        }}>{`#3850`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerConfig#server():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#server()"
        }}>{`type://ServerConfig#server()`}</a>{` doesn't raise an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` anymore
after `}<a parentName="li" {...{
          "href": "type://Server#reconfigure(ServerConfigurator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html#reconfigure(com.linecorp.armeria.server.ServerConfigurator)"
        }}>{`type://Server#reconfigure(ServerConfigurator)`}</a>{` is called. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3853"
        }}>{`#3853`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` is complete on the server-side even when an exception is raised and deferred values
are not set. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3823"
        }}>{`#3823`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ResponseConverterFunctionProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunctionProvider.html"
        }}>{`type://ResponseConverterFunctionProvider`}</a>{` now correctly works for suspending functions whose return type is
`}<a parentName="li" {...{
          "href": "type://HttpResult:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/HttpResult.html"
        }}>{`type://HttpResult`}</a>{` in Kotlin. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3844"
        }}>{`#3844`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`%2F`}</inlineCode>{` and `}<inlineCode parentName="li">{`%2f`}</inlineCode>{` are no longer converted to `}<inlineCode parentName="li">{`/`}</inlineCode>{` when decoding a request path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3855"
        }}>{`#3855`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandlerFunction.html"
        }}>{`type://ExceptionHandlerFunction`}</a>{` of an annotated service now correctly handles exceptions raised by
service decorators. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3835"
        }}>{`#3835`}</a></li>
      <li parentName="ul">{`You can now correctly send additional response headers even if the request has failed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3836"
        }}>{`#3836`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` is complete on the server-side even when an exception is raised and deferred values
are not set. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3823"
        }}>{`#3823`}</a></li>
      <li parentName="ul">{`The failed request to a `}<a parentName="li" {...{
          "href": "type://TransientService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientService.html"
        }}>{`type://TransientService`}</a>{` is now logged properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3818"
        }}>{`#3818`}</a></li>
      <li parentName="ul">{`You no longer see a pseudo request header in a response headers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3801"
        }}>{`#3801`}</a></li>
      <li parentName="ul">{`You no longer see a `}<a parentName="li" {...{
          "href": "type://ClosedSessionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ClosedSessionException.html"
        }}>{`type://ClosedSessionException`}</a>{` when a server gracefully shut down an HTTP/2 connection.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3825"
        }}>{`#3825`}</a></li>
      <li parentName="ul">{`A response headers of a `}<a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` is now correctly sent when the first response frame
is sent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3856"
        }}>{`#3856`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now correctly invokes `}<inlineCode parentName="li">{`Server.Listener`}</inlineCode>{` in order. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3805"
        }}>{`#3805`}</a></li>
      <li parentName="ul">{`gRPC client calls are now closed exactly once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3799"
        }}>{`#3799`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3800"
        }}>{`#3800`}</a></li>
      <li parentName="ul">{`Throwing an `}<a parentName="li" {...{
          "href": "type://ArmeriaStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/protocol/ArmeriaStatusException.html"
        }}>{`type://ArmeriaStatusException`}</a>{` now overrides the gRPC status code and description as expected.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3838"
        }}>{`#3838`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://SamlAssertionConsumerConfigBuilder#endpoint(SamlEndpoint):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/saml/SamlAssertionConsumerConfigBuilder.html#endpoint(com.linecorp.armeria.server.saml.SamlEndpoint)"
        }}>{`type://SamlAssertionConsumerConfigBuilder#endpoint(SamlEndpoint)`}</a>{` is deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3813"
        }}>{`#3813`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://SamlServiceProviderBuilder#acs(SamlEndpoint):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/saml/SamlServiceProviderBuilder.html#acs(com.linecorp.armeria.server.saml.SamlEndpoint)"
            }}>{`type://SamlServiceProviderBuilder#acs(SamlEndpoint)`}</a>{` instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ExceptionHandler`}</inlineCode>{` is now gone. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3853"
        }}>{`#3853`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://ServerErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html"
            }}>{`type://ServerErrorHandler`}</a>{` instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bucket4j 6.2.0 → 6.3.0`}</li>
      <li parentName="ul">{`Dropwizard 2.0.24 → 2.0.25`}</li>
      <li parentName="ul">{`Dropwizard metrics 4.2.3 → 4.2.4`}</li>
      <li parentName="ul">{`GraphQL-Java 17.1 → 17.3`}</li>
      <li parentName="ul">{`gRPC-Java 1.40.1 → 1.41.0`}</li>
      <li parentName="ul">{`Jackson 2.12.4 → 2.13.0`}</li>
      <li parentName="ul">{`java-jwt 3.18.1 → 3.18.2`}</li>
      <li parentName="ul">{`Kotlin 1.5.10 → 1.5.21`}</li>
      <li parentName="ul">{`Kafka 2.8.0 -> 3.0.0`}</li>
      <li parentName="ul">{`Kotlin 1.5.21 → 1.5.31`}</li>
      <li parentName="ul">{`Kotlin Coroutines 1.5.1 → 1.5.2`}</li>
      <li parentName="ul">{`Logback 1.2.5 → 1.2.6`}</li>
      <li parentName="ul">{`Micrometer 1.7.3 → 1.7.4`}</li>
      <li parentName="ul">{`Netty 4.1.66 → 4.1.68`}</li>
      <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.40 → 2.0.43`}</li>
      <li parentName="ul">{`Prometheus simpleclient 0.11.0 → 0.12.0`}</li>
      <li parentName="ul">{`Reactor 3.4.9 → 3.4.10`}</li>
      <li parentName="ul">{`RESTEasy 4.7.1 → 4.7.2`}</li>
      <li parentName="ul">{`RxJava 3.1.0 → 3.1.1`}</li>
      <li parentName="ul">{`scala-java8-compat 1.0.0 → 1.0.1`}</li>
      <li parentName="ul">{`Spring 5.3.9 → 5.3.10`}</li>
      <li parentName="ul">{`Spring Boot 2.5.4 → 2.5.5`}</li>
      <li parentName="ul">{`Thrift 0.14.2 → 0.15.0`}</li>
      <li parentName="ul">{`Tomcat 9.0.46 → 9.0.53`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'berry120', 'fantayeneh', 'freevie', 'ghkim3221', 'heowc', 'ikhoon', 'jasper-vandemalle', 'jrhee17', 'kezhenxu94', 'ks-yim', 'minwoox', 'TheWeaVer', 'trustin', 'vivekkothari']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      